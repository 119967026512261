<template>
  <div class="article">
    <video v-if="data.video_url" :src="data.video_url" controls></video>
    <!-- <div class="article-content" v-html="data.content"></div> -->
    <div class="ql-snow">
      <div class="article-content ql-editor" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({}),
  methods: {},
}
</script>

<style lang="scss" scoped>
@import url(../../assets/style/quill.core.css);
@import url(../../assets/style/quill.bubble.css);
@import url(../../assets/style/quill.snow.css);

video {
  width: 100%;
}

.ql-snow {
  display: flex;
  justify-content: center;
}

.article-content {
  // margin-bottom: 84px;
  font-size: 16px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #161616;
  line-height: 30px;
  text-indent: 24px;

  max-width: 1108px;
  margin: 0 auto 84px auto;

  /deep/ {
    .ql-size-huge {
      line-height: 1.5em;
    }

    .ql-size-large {
      line-height: 1.5em;
    }

    h1 {
      line-height: 1.5em;
    }

    strong {
      font-weight: bold !important;
    }

    em {
      font-style: italic !important;
    }

    u {
      text-decoration: underline;
    }

    s {
      text-decoration: line-through;
    }

    li {
      position: relative;

      &::before {
        position: absolute;
        left: 2em;
      }
    }

    img {
      max-width: 80%;
      margin: 32px auto;
      object-fit: contain;
    }

    .ql-video {
      height: 512px;
      width: 80%;
      margin: 32px auto;
      background-color: transparent;
    }
  }
}
</style>
