import request from '@/axios/index.js';

//精选壁纸
export function apiHelpTree(query) {
  return request({
      headers: {
          port: 9999
      },
      url: '/official/operation_guide/trees',
      method: 'GET',
      params: query
  })
}