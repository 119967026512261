<template lang="html">
  <!-- -->
  <div class="container-wrapper">
    <!-- <div class="bg"></div> -->
    <div class="bg-help"></div>
    <div class="layout">
      <img class="instructions" src="../../assets/images/help/instructions.png" alt="">
      <div class="container">
        <aside class="aside">
          <el-scrollbar ref="scrollbar">
            <ul class="menu">
              <li class="menu-item" v-for="parent in tree" @click.stop="onItemClick(parent)">
                <section class="menu-name"
                  :class="{ active: currentParent ? parent.id == currentParent.id : parent.id == currentItem.id }">
                  {{ parent.name }}
                  <!-- <div class="menu-active"
                    :class="{ active: currentParent ? parent.id == currentParent.id : parent.id == currentItem.id }">
                  </div> -->
                  <!-- <div class="menu-expand" :class="{ expand: parent.expand }" -->
                  <div class="menu-expand"
                    :class="{ expand: currentParent ? parent.id == currentParent.id : parent.id == currentItem.id, open: parent.expand }"
                    v-if="parent.children && parent.children.length"></div>
                  <!-- <div class="arrows"></div> -->
                </section>
                <ul class="child-menu" v-if="parent.expand && parent.children">
                  <li class="child-menu-item" v-for="child in parent.children"
                    @click.stop="onChildItemClick(child, parent)" :class="{ active: currentItem.id == child.id }">
                    <div class="child-item-name">
                      {{ child.name }}
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </el-scrollbar>
          <!-- Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est nostrum, vitae id accusamus quasi alias quibusdam exercitationem cum nulla pariatur accusantium omnis assumenda in facilis dicta sunt similique, ea recusandae! -->
        </aside>
        <main>
          <!-- <header class="header" @click="goHelp()"></header> -->
          <section class="path">
            <!-- <a class="path-link" href="/help">操作指南</a>
          <span class="path-arrow">&gt;&gt;</span> -->
            <!-- <span class="path-link" @click="currentItem = currentParent">{{ currentParent ? currentParent.name : '' }}</span> -->
            <span class="path-link">{{ currentParent ? currentParent.name : '' }}</span>
            <span class="path-arrow" v-if="currentParent">--&gt;</span>
            <span class="path-link active">{{ currentItem.name }}</span>
          </section>
          <section class="title">{{ currentItem.name }}</section>
          <div class="spacer"></div>
          <Article :data="currentItem"></Article>
        </main>
      </div>
    </div>
  </div>
  <!--  -->
</template>

<script>
import { apiHelpTree } from '@/api/help.js'
import pageBg from '../../components/pageBg'
import Article from './article.vue'

export default {
  components: { pageBg, Article },
  data: () => ({
    tree: [],
    currentItem: {},
  }),
  computed: {
    currentParent() {
      return this.tree.find(item => item.id == this.currentItem.parent_id)
    },
  },
  methods: {
    onItemClick(item) {
      if (item.children && item.children.length) {
        this.$set(item, 'expand', !item.expand)
      } else {
        this.currentItem = item
        this.scrollToTop()
      }
    },
    onChildItemClick(item) {
      this.currentItem = item
      this.scrollToTop()
    },
    scrollToTop() {
      console.log('xzxxxxxxxxxxxx')
      // document.querySelector('#app').scrollIntoView()
      // console.log(document.querySelector('#app'));
      document.querySelector('.el-scrollbar__view').scrollIntoView()
      // document.querySelector('#app').scrollTop = 0
      // document.querySelector('#app').scrollIntoView()
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    async getData() {
      this.tree = (await apiHelpTree()).data
      console.log(this.tree)
    },
    goHelp() {
      window.open('https://support.qq.com/products/57493', '_blank')
    },
    // goPath(pIdx, cIdx) { // 点开对应的二级菜单 收到的是下标

    //   if (pIdx > 0) {
    //   this.tree[0].expand = false
    //   this.onItemClick(this.tree[pIdx])
    //   this.onChildItemClick(this.tree[pIdx].children[cIdx], this.tree[pIdx])
    //   } else {
    //   this.onChildItemClick(this.tree[pIdx].children[cIdx], this.tree[pIdx])
    //   }

    // },
    checkOutLink() {
      let id = this.$route.query.id
      let item
      if (!id) return
      for (let i = 0; i < this.tree.length; i++) {
        if (this.tree[i].id === id) {
          this.tree[0].expand = false
          item = this.tree[i]
          // console.log('item', item);
          this.onChildItemClick(item)
          break
        }
        for (let j = 0; j < this.tree[i].children.length; j++) {
          if (this.tree[i].children[j].id === id) {
            item = this.tree[i].children[j]
            if (i > 0) {
              this.tree[0].expand = false
              this.tree[i].expand = true
            }
            // console.log('item:', item);
            this.onChildItemClick(item)
            break
          }
        }
      }
    }
  },
  async mounted() {
    document.querySelector('.mainBody').style.background = 'rgba(4, 6, 23, 1)'
    await this.getData()
    try {
      if (this.tree && this.tree[0]) {
        const { pid, cid } = this.$route.query
        if (pid) {
          const parent = this.tree.find(item => item.id == pid)
          if (cid && parent.children && parent.children.length) {
            const child = parent.children.find(child => child.id == cid)
            this.$set(parent, 'expand', true)
            this.currentItem = child
          } else {
            this.currentItem = parent.children && parent.children.length ? parent.children[0] : parent
          }
        } else if (this.tree[0].children && this.tree[0].children.length) {
          this.currentItem = this.tree[0].children[0]
          this.$set(this.tree[0], 'expand', true)
        } else {
          this.currentItem = this.tree[0]
        }
      }
    } catch (error) {
      console.log(error)
    }
    // this.goPath(this.pIdx, 2)// 这里收到一个孩子下标和一个双亲下标，就可以打开对应页面
    // console.log(this.pIdx);
    //移动到这里
    this.checkOutLink()

    let el = document.getElementsByClassName('container-wrapper');
    this.$content = el[0];
    this.$content.style.paddingBottom = '189px';
  },
  destroyed() {
    this.$content.style.paddingBottom = '0px';
  },
  //重复了！！！
  // mounted() {
  //   this.checkOutLink()
  // },  
  // created() {
  //   EventBus.$on('instructionPIdx', (pIdx) => {
  //     console.log('跨页面能用bus吗？');
  //     this.pIdx = pIdx
  //   })
  // },

  // beforeDestroy() {
  //   EventBus.$off('instructionPIdx')
  // },
}
</script>

<style lang="scss" scoped>
.container-wrapper {
  position: relative;
}

.bg-help {
  position: absolute;
  // top: -130px;
  // left: 0;
  width: 100%;
  max-width: 1920px;
  height: 332px;
  left: 50%;
  transform: translate(-50%, 0);
  background: url('../../assets/images/help/banner.png') no-repeat top center;
  // background-size: 100% auto;
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.layout {
  position: relative;
  top: 148px;
  // width: 100%;
  // height: 100%;
  min-width: 1200px;

  width: 1300px;
  left: 50%;
  transform: translate(-50%, 0);
}

.instructions {
  // position: fixed;
  position: absolute;
  // top: -96px;
  top: -110px;
  // left: 310px;
}

.container {
  position: relative;
  display: flex;
  // justify-content: center;
  // margin: 130px 380px 0 310px;
  min-height: 100vh;
  font-family: 'Microsoft YaHei';
  z-index: 99;

  main {
    // flex: 0 0 800px;
    // width: 0;
    // margin: 0 auto;
    // margin-left: 169px;
    position: relative;
    // left: 54px;
    margin-left: 54px;

    width: 1016px;

    header {
      width: 100%;
      height: 58px;
      background: url('../../assets/images/help/ad.png') no-repeat;
      background-size: contain;
      background-position: center;
      cursor: pointer;
    }

    .path {
      position: relative;
      left: 0;
      display: flex;
      align-items: center;

      // height: 72px;
      // line-height: 80px;
      // padding: 36px 0;
      padding-bottom: 26px;
      font-size: 16px;
      color: #161616;

      .path-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 12px;
        height: 12px;
        padding: 0 10px;
        // letter-spacing: -6px;
        // transform: scaleX(0.5);
        color: #161616;
      }

      .path-link {
        // opacity: 0.7;
        // font-weight: Regular;
        cursor: pointer;

        &.active {
          // font-size: 20px;
          font-weight: bold;
          opacity: 1;
        }
      }
    }

    .title {
      color: #161616;

      font-size: 24px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
    }

    .spacer {
      position: relative;

      width: 1016px;
      height: 1px;
      left: 0;
      background: #E9F0F6;

      // width: 100%;
      // height: 1px;
      margin-bottom: 36px;
      // background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.3);
      z-index: 0;

      // &::before,
      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: -1px;
      //   width: 11px;
      //   height: 2px;
      //   background-color: rgba($color: rgb(0, 0, 0), $alpha: 0.9);
      //   z-index: 1;
      // }

      // &::before {
      //   left: 0;
      // }

      // &::after {
      //   right: 0;
      // }
    }
  }
}

aside {
  // position: fixed;
  position: sticky;
  top: 90px;
  // left: 310px;
  display: flex;
  width: 228px;
  // height: 704px;
  height: 634px;
  // opacity: 0.7;
  border-radius: 8px;
  align-self: flex-start;
  padding: 14px 0;
  background: rgba(233, 240, 246, 1);
  font-size: 24px;
  color: #161616;
  z-index: -999;

  // margin-left: 310px;

  .el-scrollbar {
    width: 228px;
    // height: 704px;
  }

  .menu {
    padding: 0 24px;

    .menu-item {
      position: relative;
      cursor: pointer;

      .child-menu {
        .child-menu-item {
          position: relative;
          right: 12px;
          width: 204px;
          font-size: 16px;
          line-height: 40px;
          font-weight: 400;
          color: #161616;

          .child-item-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 42px;
          }

          // &::before {
          //   content: '';
          //   position: absolute;
          //   width: 32px;
          //   height: 3px;
          //   left: -24px;
          //   top: calc(50% - 1px);
          //   background: url('../../assets/images/help/item-active.png');
          //   z-index: -1;
          //   opacity: 0;
          // }

          &:hover {
            color: #3DA7FE;
          }

          &.active {
            font-weight: bold;
            color: #3DA7FE;
            background-color: #fff;
            border-radius: 8px;

            // &::before {
            //   opacity: 1;
            // }
          }
        }
      }

      .menu-name {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #161616;
        position: relative;
        display: flex;
        align-items: center;
        height: 52px;
        line-height: 52px;

        &:hover {
          color: #3DA7FE;
        }

        &:hover .menu-expand {
          background-color: #3DA7FE;
        }

        &.active {
          //parents text
          color: #3DA7FE;
        }

        // &:hover .arrows {
        //   background: #3DA7FE;
        // }

        // .arrows {
        //   margin-left: 8px;
        //   width: 10px;
        //   height: 6px;
        //   background: #161616;
        //   clip-path: polygon(0% 0%, 0% 0%, 100% 0%, 50% 100%);
        // }

        // .menu-active {
        //   position: absolute;
        //   left: -24px;
        //   bottom: 14px;
        //   width: 100%;
        //   height: 14px;
        //   z-index: -1;
        //   opacity: 0;
        //   transform: translateX(-10%);
        //   transition: 0.25s ease;

        //   &::before {
        //     content: '';
        //     position: absolute;
        //     background-color: #4050ff;
        //     width: calc(100% - 10px);
        //     height: 100%;
        //   }

        //   &::after {
        //     content: '';
        //     position: absolute;
        //     right: 0;
        //     bottom: 0;
        //     width: 10px;
        //     height: 14px;
        //     background: url('../../assets/images/help/angle.png') no-repeat;
        //     background-size: contain;
        //     background-position: center;
        //   }

        //   &.active {
        //     transform: translateX(0%);
        //     opacity: 1;
        //   }
        // }


        &:hover .menu-expand .expand {
          background-color: #161616;
        }

        .menu-expand {
          // width: 12px;
          // height: 8px;
          width: 10px;
          height: 6px;
          margin-left: 8px;
          background: #161616;
          clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
          // background: url('../../assets/images/help/icon-down.png') no-repeat;
          // background-size: contain;
          // background-position: center;

          &.expand {
            // background: url('../../assets/images/help/icon-up.png') no-repeat;
            background: #3DA7FE;
          }

          &.open {
            clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
          }
        }
      }
    }
  }
}
</style>
