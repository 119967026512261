<!--  -->
<template>
<div class="pageImg">
        <parallax-container class="bgImg_1">
            <slot></slot>
            <parallax-element :parallaxStrength="5" :type="'depth'" class="topBg">
                <img src="../../assets/img/pageBg/pageTop.png">
            </parallax-element>
            <parallax-element :parallaxStrength="3" :type="'depth'" class="leftRight">
                <img src="../../assets/img/pageBg/pageCen.png">
            </parallax-element>
            <parallax-element :parallaxStrength="2" :type="'depth'" class="bottomBg">
                <img src="../../assets/img/pageBg/pageBtm.png">
            </parallax-element>
        </parallax-container>
        <div class="leftBg">
            <img src="../../assets/img/pageBg/pageLeft.png">
        </div>
        <div class="rightBg">
            <img src="../../assets/img/pageBg/pageRight.png">
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {
        heightBg: null
    },
    components: {
    
    },
    data() {
        return {

        };
    },
    computed: {},

    methods: {

    },
}
</script>

<style lang="scss" scoped>
.pageImg {
  width: 100%;
  background: url('../../assets/img/pageBg/pageBg.png') no-repeat top center;
  // background-size: inherit;
  background-size: 100% auto;
  position: relative;
  overflow: hidden;
  .bgImg_1 {
    .topBg {
      width: 76%;
      position: absolute;
      top: 188px;
      left: 12%;
      z-index: -1;
      transform: rotateX(0);
    }

    .leftRight {
      position: absolute;
      width: 82.29%;
      top: 246px;
      left: 9%;
      z-index: -1;
      transform: rotateX(0);
    }

    .bottomBg {
      position: absolute;
      width: 100%;
      top: 688px;
      left: 0;
      z-index: -1;
      transform: rotateX(0);
      img {
        width: 106%;
      }
    }
  }

  .leftBg {
    position: absolute;
    top: 358px;
    width: 2.5%;
    left: 0;
    z-index: 999;
  }

  .rightBg {
    position: absolute;
    top: 358px;
    width: 2.5%;
    right: 0;
    z-index: 999;
  }
}
</style>
